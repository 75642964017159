import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link} from 'react-router-dom';

function NavBar() {
    const currentTab = () => {
        let path = window.location.pathname
        if (path === "/") return 0
        else if (path === "/feature") return 1
        else if (path === "/price") return 2
        else if (path === "/direction") return 3
        else if (path === "/tour") return 4
        else if (path === "/contact") return 5
      }
    const [value, setValue] = React.useState(currentTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <Tabs
            className="nav-bar"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
        >
            <Tab label="토끼네 집" component={Link} to="/"></Tab>
            <Tab label="특징" component={Link} to="/feature"/>
            <Tab label="사진/가격" component={Link} to="/price"/>
            <Tab label="오시는 길" component={Link} to="/direction"/>
            <Tab label="관광 명소" component={Link} to="/tour"/>
            <Tab label="연락처" component={Link} to="/contact"/>
        </Tabs>
    )
}

export default NavBar;
