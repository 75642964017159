import { Card, CardContent, CardMedia, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';

function Feature() {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={9}> 
                <Card>
                    <CardContent>
                        <Typography sx={{fontWeight:'bold'}} variant={'h5'} color={'#22577E'} component={'div'} gutterBottom>
                            토끼네 집 특징!
                        </Typography>
                        <h3>가깝고 편리한 교통시설과 쇼핑센터</h3>
                        <ul>
                            <li>Yorkdale 전철역에서 집까지 도보 2분거리</li>
                            <li>Yorkdale Go Bus 터미널까지 도보 2분거리</li>
                            <li>Yorkdale mall까지 집에서 도보 2분거리 </li>
                            <li>전철로 15~20분 거리에 위치한 한인타운</li>
                            <li>공항에서 집까지 15~20분거리 </li>
                            <li>Rent Car 회사 밀집지역까지 도보 8분거리 </li>
                        </ul>
                        <h3>내 집 같이 편안한 시설</h3>
                        <ul>
                            <li>공동사용 주방 2개, 세탁기 </li>
                            <li>모든 방에 침대, 가구, 고속 인터넷 완비 </li>
                        </ul>
                        <h3>편안한 휴식공간</h3>
                        <ul>
                            <li>집 바로 뒷뜰이 Yorkdale Park </li>
                            <li>길 맞은편에 위치한 Baycrest Park </li>
                            <li>지나는 차량이 없는 조용한 주택가 </li>
                        </ul>
                        <h3>가까운 캐나다 명문 대학</h3>
                        <ul>
                            <li>Toronto 대학이 전철로 15~20분 거리 위치 </li>
                            <li>York 대학이 전철로 15~20분 거리 위치 </li>
                            <li>George Brown College가 전철로 15분 거리 위치</li>
                        </ul>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardContent>
                        <Hidden smDown>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                src={process.env.PUBLIC_URL + '/images/rabbit.jpg'}
                                alt="Rabbit">
                            </CardMedia>
                        </Hidden>
                        <Typography variant={'p'}>
                            빠른 문의
                            <br />(416) 270-7101
                            <br />카톡ID : kpholly2540
                            <br />holly0820@hanmail.net
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Feature;