import { Card, CardContent, CardMedia, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';

function Direction() {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={9}> 
                <Card>
                    <CardContent>
                        <Typography sx={{fontWeight:'bold'}} variant={'h5'} color={'#22577E'} component={'div'} gutterBottom>
                            오시는 길
                        </Typography>
                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>GO Transit</h3>
                                <img src={process.env.PUBLIC_URL + '/images/gotransit.jpg'} alt="gotransit"/>
                                <p>GOtransit은 광역토론토를 이어주는 일종의 시외버스입니다. 공항에서 Yorkdale Terminal가는 버스는 대략 한시간 간격으로 있으며 Pearson 공항 2번 터미널의 20번 기둥(Post)에서 탈 수 있습니다. 여기서 Brampton Local, Hwy 27, &amp; Hwy 427 Express GO Bus 의 EAST 방면(inbound) 을 탑니다. 기사에게 Yorkdale Bus terminal에서 내린다고 이야기 하십시오.</p>
                                <ul>
                                    <li>갈아타지 않고 빠르지만 버스가 자주 없습니다. 차가 금방 출발한 경우 TTC를 이용하십시오.</li>
                                    <li>성인 요금: $4.05.</li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>Toronto Transmit Commision (TTC)</h3>
                                <img src={process.env.PUBLIC_URL + '/images/ttc.jpg'} alt="ttc"/>
                                <p>TTC 58A버스가 Pearson 공항에서 <strong>Lawrence West Station</strong> 까지 새벽 5:00에서 1:00까지 운행됩니다. Lawrence West Station은 Yorkdale Station 바로 남쪽역이므로 여기서 북쪽방면 Subway를 타고 한 역만 더오시면 됩니다.</p>
                                <ul>
                                    <li>
                                        <a href="http://www.toronto.ca/ttc/service_to_airport.htm">
                                        Malton 58A 노선 및 운행시간표 </a>
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>렌트카</h3>
                                <p>Avis, Herts, Enterprise, Budget, Discount의 렌트카 오피스가 집에서 도보 3-7분거리의 Dufferin Street에 모여있습니다. 해당 렌트카회사의 공항 사무실에서 Pickup 하시고 하루 종일 용건을 보신후 민박에 오셔서 다음날 아침 일찍 집가까운 사무실에 돌려주시면 매우 편리합니다. 예약은 전화, 인터넷 등으로 가능합니다. 다음을 클릭하시면 렌트카회사와 공항에서 집까지 렌트카로 오는 길을 안내받을 수 있습니다.</p>
                                <ul>
                                    <li>나이아가라, 천섬 등 가까운 곳으로 가실 여행자나 업무용으로 토론토에 오신 분은 편리합니다.</li>
                                    <li>차종과 회사마다 다릅니다만 보험료를 제외하면 하루 24시간 기준으로 35-45불 정도 입니다.</li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>택시 이용</h3>
                                <p>공항에서 택시를 타고 "I want to go Yorkdale Bus Terminal"이라고 하시든가, 주소를 보여주시면 정확히 데려다 줍니다.</p>
                                <ul>
                                    <li>특별히 짐이 많지 않으면 비싸서 권하고싶지 않습니다.</li>
                                    <li>35불-40불 정도의 요금에 3불정도 팁을 주시면 됩니다.</li>
                                </ul>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Grid>
            
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardContent>
                        <Hidden smDown>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                src={process.env.PUBLIC_URL + '/images/rabbit.jpg'}
                                alt="Rabbit">
                            </CardMedia>
                        </Hidden>
                        <Typography variant={'p'}>
                            빠른 문의
                            <br />(416) 270-7101
                            <br />카톡ID : kpholly2540
                            <br />holly0820@hanmail.net
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Direction;