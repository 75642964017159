import { Box, Card, CardContent, Grid, Typography} from '@mui/material';
import React from 'react';
import ImageGallery from 'react-image-gallery';

function Price() {
    const images = [
        {
            title: 'room1',
            original: process.env.PUBLIC_URL +'/images/room1.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room1.jpg',
            originalAlt: '1 of rooms',
            thumbnailAlt: '1 of rooms - thumbnail',
        },
        {
            title: 'room2',
            original: process.env.PUBLIC_URL +'/images/room2.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room2.jpg',
            originalAlt: '2 of rooms',
            thumbnailAlt: '2 of rooms - thumbnail',
        },
        {
            title: 'room3',
            original: process.env.PUBLIC_URL +'/images/room3.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room3.jpg',
            originalAlt: '3 of rooms',
            thumbnailAlt: '3 of rooms - thumbnail',
        },
        {
            title: 'room4',
            original: process.env.PUBLIC_URL +'/images/room4.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room4.jpg',
            originalAlt: '4 of rooms',
            thumbnailAlt: '4 of rooms - thumbnail',
        },
        {
            title: 'room5',
            original: process.env.PUBLIC_URL +'/images/room5.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room5.jpg',
            originalAlt: '5 of rooms',
            thumbnailAlt: '5 of rooms - thumbnail',
        },
        {
            title: 'room6',
            original: process.env.PUBLIC_URL +'/images/room6.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room6.jpg',
            originalAlt: '6 of rooms',
            thumbnailAlt: '6 of rooms - thumbnail',
        },
        {
            title: 'room7',
            original: process.env.PUBLIC_URL +'/images/room7.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/room7.jpg',
            originalAlt: '7 of rooms',
            thumbnailAlt: '7 of rooms - thumbnail',
        },
        {
            title: 'kitchen',
            original: process.env.PUBLIC_URL +'/images/kitchen.jpg',
            thumbnail: process.env.PUBLIC_URL +'/images/kitchen.jpg',
            originalAlt: 'kitchen image',
            thumbnailAlt: 'kitchen - thumbnail',
        },
          
        ];
    return(
        <Box>
           <ImageGallery 
           thumbnailPosition={'top'}
           showPlayButton={false}
           showBullets={true}
           slideOnThumbnailOver={true}
           items={images} />
           <Grid>
                <Card>
                    <CardContent>
                        <Typography sx={{fontWeight:'bold'}} variant={'h5'} color={'#22577E'} component={'div'} gutterBottom>
                            가격 안내
                        </Typography>
                        <h3>2인 1실</h3>
                        <ul>
                            <li>$950 ~ $1200 </li>
                            <li>커플 환영!</li>
                        </ul>
                        <h3>1인 1실</h3>
                        <ul>
                            <li>1층, 2층 - $850 ~ $900</li>
                            <li>반지하 - $650 ~ $700</li>
                        </ul>
                        <Typography pb={2}>
                            - Wi-Fi, 침대, 책상, 가구 포함
                        </Typography>
                        <Typography sx={{borderTop:1,padding:2}}>
                        자세한 문의내용은 연락 주세요.
                        <h4>
                        전화번호: (416) 270-7101<br />
                        카톡ID : kpholly2540<br />
                        E-mail: holly0820@hanmail.net
                        </h4>
                        </Typography>
                    </CardContent>
                </Card>
        </Grid>
        </Box>
    )
}

export default Price;
