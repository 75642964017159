import React from 'react';

function Page404() {
    return(
        <div>
            <h1>404</h1>
            <h2>Page Not Found</h2>
        </div>
    )
}

export default Page404;