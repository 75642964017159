import { Card, CardContent, CardMedia, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';

function Tour() {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={9}> 
                <Card>
                    <CardContent>
                        <Typography sx={{fontWeight:'bold'}} variant={'h5'} color={'#22577E'} component={'div'} gutterBottom>
                            관광 명소
                        </Typography>
                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>Niagara Falls</h3>
                                <img className="photo" src={process.env.PUBLIC_URL + '/images/NiagaraFalls.jpg'} alt="NiagaraFalls"/>
                                <ul>
                                    <li>차로 나이아가라 폭포 까지 2시간거리</li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>Toronto CityHall</h3>
                                <img className="photo" src={process.env.PUBLIC_URL + '/images/CityHall.jpg'} alt="CityHall"/>
                                <ul>
                                    <li>
                                    전철로 집에서 20분거리에 위치한 토론토 시청
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>Toronto Museum (ROM)</h3>
                                <img className="photo" src={process.env.PUBLIC_URL + '/images/TorontoMuseum.jpg'} alt="TorontoMuseum"/>
                                <ul>
                                    <li>
                                    전철로 집에서 15분 거리에 위치한 토론토 박물관
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>Casa Loma</h3>
                                <img className="photo" src={process.env.PUBLIC_URL + '/images/CasaLoma.jpg'} alt="CasaLoma"/>
                                <ul>
                                    <li>
                                    전철로 집에서 카사로마까지 5정거장
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>CN Tower</h3>
                                <img className="photo" src={process.env.PUBLIC_URL + '/images/CNTower.jpg'} alt="NiagaraFalls"/>
                                <ul>
                                    <li>
                                    CN 타워까지 전철로 30분거리
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>

                        <Card sx={{mb:2}}>
                            <CardContent>
                                <h3>Canada's Wonderland</h3>
                                <img className="photo" src={process.env.PUBLIC_URL + '/images/Wonderland.jpg'} alt="Wonderland"/>
                                <ul>
                                    <li>
                                    집앞에서 원더랜드 놀이공원까지 GO 버스로 30분 거리
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>

                    </CardContent>
                </Card>
            </Grid>
            
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardContent>
                        <Hidden smDown>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                src={process.env.PUBLIC_URL + '/images/rabbit.jpg'}
                                alt="Rabbit">
                            </CardMedia>
                        </Hidden>
                        <Typography variant={'p'}>
                            빠른 문의
                            <br />(416) 270-7101
                            <br />카톡ID : kpholly2540
                            <br />holly0820@hanmail.net
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Tour;