import React from 'react';
import { Box, Typography } from '@mui/material';

function Header() {
    return(
        <Box className="header">
            <Typography sx={{fontWeight:'lighter'}} variant={'h3'} align={'center'}>
                토끼네 집
            </Typography>
        </Box>
    )
}

export default Header;