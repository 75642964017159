import { Card, CardContent, CardMedia, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';

function Home() {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={9}> 
                <Card>
                    <CardContent>
                        <Typography sx={{fontWeight:'bold'}} variant={'h5'} color={'#22577E'} component={'div'} gutterBottom>
                            토끼네 집에 오신걸 환영합니다.
                        </Typography>
                        <CardMedia 
                            sx={{mb:2}}
                            component="img" 
                            src={process.env.PUBLIC_URL + '/images/OutsideView.jpg'}
                            alt="Outside">
                        </CardMedia>
                        <Typography variant={'p'} component={'div'} gutterBottom>
                            토론토 토끼네 집은 편리한 교통시설과 아늑한 분위기를 갖춘, 유학생과 여행자 여러분이 편안하게 쉴 
                            수 있는 고급 주택가에 위치한 주택입니다. 
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardContent>
                        <Hidden smDown>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                src={process.env.PUBLIC_URL + '/images/rabbit.jpg'}
                                alt="Rabbit">
                            </CardMedia>
                        </Hidden>
                        <Typography variant={'p'}>
                            빠른 문의
                            <br />(416) 270-7101
                            <br />카톡ID : kpholly2540
                            <br />holly0820@hanmail.net
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Home;