import React from 'react';

function Footer() {
    return (
        <footer className="footer"> 
            <small>&copy; Copyright 2022. www.torontoroom.com </small> 
        </footer>     
    )
}

export default Footer;