import * as React from 'react';
import './App.css';
import { Container, Paper} from '@mui/material';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

//components
import NavBar from './components/NavBar';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Feature from './components/Feature';
import Price from './components/Price';
import Direction from './components/Direction';
import Tour from './components/Tour';
import Contact from './components/Contact';
import Page404 from './components/Page404';

function App() {
  return (
    <BrowserRouter>
        <Container sx={{padding:0}} maxWidth="md">
          <Header />
          <NavBar />
          <Paper sx={{marginBottom:2}} elevation={0}>
            <Switch>
              <Route exact path="/"><Home /></Route>
              <Route path="/feature"><Feature /></Route>
              <Route path="/price"><Price /></Route>
              <Route path="/direction"><Direction /></Route>
              <Route path="/tour"><Tour /></Route>
              <Route path="/contact"><Contact /></Route>
              <Route component={Page404}></Route>
            </Switch>
          </Paper>
          <Footer />
        </Container>
    </BrowserRouter>
  );
}

export default App;
