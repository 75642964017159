import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Noto Sans KR", sans-serif',
        fontSize: 15,
        p: {
            fontSize: 15,
        }
    }
})

export default theme;