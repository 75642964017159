import { Card, CardContent, CardMedia, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';

function Contact() {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={9}> 
                <Card>
                    <CardContent>
                        <Typography sx={{fontWeight:'bold'}} variant={'h5'} color={'#22577E'} component={'div'} gutterBottom>
                            연락처
                        </Typography>
                        <h4>전화번호: (416) 270-7101</h4>
                        <h4>카톡ID : kpholly2540</h4>
                        <h4>E-mail: holly0820@hanmail.net</h4>
                    </CardContent>
                </Card>

            </Grid>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardContent>
                        <Hidden smDown>
                            <CardMedia 
                                sx={{mb:1}}
                                component="img"
                                src={process.env.PUBLIC_URL + '/images/rabbit.jpg'}
                                alt="Rabbit">
                            </CardMedia>
                        </Hidden>
                        <Typography variant={'p'}>
                            빠른 문의
                            <br />(416) 270-7101
                            <br />카톡ID : kpholly2540
                            <br />holly0820@hanmail.net
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Contact;